import { createStore } from 'redux';

const initialState = {
  currentLanguage: ''
};

function kazpostStore(state = initialState, action) {
  switch (action.type) {
    case 'SIGN_IN':
      return {
        ...state,
        account: action.payload
      };
    case 'SET_LOCALIZATION':
      return {
        ...state,
        currentLanguage: action.payload,
      };
    case 'SET_LOCKERS':
      return {
        ...state,
        lockers: action.payload,
      };
    case 'LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state
  }
}

let store = createStore(kazpostStore);

export default store;